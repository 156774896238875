import React from "react";

class GlobalFunctions extends React.Component {
    static   extractToken() {
        var params = {};
        var parser = document.createElement('a');
        parser.href = window.location.href;
        var query = parser.search.substring(1);
        var vars = query.split('&');
        for (var i = 0; i < vars.length; i++) {
          var pair = vars[i].split('=');
          params[pair[0]] = decodeURIComponent(pair[1]);
        }
    
        if (params.ACCESS_LEVEL != undefined) {
          localStorage.setItem("token", params.TOCKEN);
          localStorage.setItem("staff_id", params.STAFF_ID);
          localStorage.setItem("access_level", params.ACCESS_LEVEL);
        }
        const data = {
          TOCKEN: localStorage.getItem('token'),
          ACCESS_LEVEL: localStorage.getItem('access_level'),
          STAFF_ID: localStorage.getItem('staff_id')
        }
    
        return params.ACCESS_LEVEL == undefined ? data : params
      }
    // ...etc
  }


  export default GlobalFunctions;