/*!

=========================================================
* Now UI Dashboard React - v1.4.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/now-ui-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

import axios from "axios";
import moment from "moment"

// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";

// reactstrap components
import {
  Card,
  Form,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Table,
  Button,
  Label,
  FormGroup,
  Input,
  UncontrolledTooltip,
  InputGroup,
  InputGroupText,
  InputGroupAddon
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import GlobalFunctions from "../controllers/GlobalFunctions";
import StatisticalAnalysis from "../controllers/StatisticalAnalysis";
import API_URL from '../variables/API_URL';

import {
  dashboardPanelChart,
  dashboardShippedProductsChart,
  dashboardAllProductsChart,
  dashboard24HoursPerformanceChart,
} from "variables/charts.js";

class Dashboard extends React.Component {

  state = {
    incidents: [],
    globalData: [],
    IncidentRegisterLineGragh: {},
    weekStart: "",
    weekEnd: "",
    filterByDate: true
  }

  //FUNCTION THAT RUNS WHEN COMPONENT MOUNTS
  componentDidMount = () => {
    StatisticalAnalysis.returnIncidentRegisterVariables().then(result => {
      this.setState({ IncidentRegisterLineGragh: result });
    })
    this.calculateWeekRange();

  }

  //FUNCTION FOR GETTING ALL INCIDENT FROM DATABASE
  getIncidents = () => {

    var url = `${API_URL.url}/incidents/all?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;

    axios.get(url).then(incident => {

      this.setState({ incidents: incident.data, globalData: incident.data });

    }).catch(error => {

    })
  }

  getIncidentsFilteredByDate = (event) => {

    var url = `${API_URL.url}/incidents/range?start=${event.weekStart}&end=${event.weekEnd}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;

    axios.get(url).then(incident => {

      this.setState({ incidents: incident.data, globalData: incident.data });

    }).catch(error => {

    })
  }

  handleChange = event => {
    this.setState({ searchInput: event.target.value }, () => {
      this.globalSearch();
    });
  };

  globalSearch = () => {
    let { searchInput } = this.state;
    let filteredData = this.state.globalData.filter(value => {

      return (
        (
          (value.tblparticipant ? value.tblparticipant.firstname.toLowerCase().includes(searchInput.toLowerCase()) : false) ||
          (value.tblparticipant ? value.tblparticipant.lastname.toLowerCase().includes(searchInput.toLowerCase()) : false)
        )
      );

    });
    console.log(filteredData);
    this.setState({ incidents: filteredData });
  };

  calculateWeekRange = () => {

    let now = moment();
    var day = Number(now.weekday())
    const start = moment().startOf('month').format('MM-DD-YYYY');
    const end = moment().endOf('month').format('MM-DD-YYYY');
    // var start = now.subtract(day, 'days').format('MM-DD-YYYY');
    // var end = now.add(6, 'days').format('MM-DD-YYYY')

    this.setState({ weekStart: start, weekEnd: end })
    this.getIncidentsFilteredByDate({ weekStart: start, weekEnd: end });

    console.log(this.state.weekStart);

  }

  getNextWeek = () => {
    // var start = moment(this.state.weekEnd).format('MM-DD-YYYY');
    // start = moment(start).add(1, 'days').format('MM-DD-YYYY');
    // var end = moment(start).add(6, 'days').format('MM-DD-YYYY')

    var start = moment(this.state.weekEnd).add(1, "days").format('MM-DD-YYYY');
    var end = moment(start).endOf('month').format('MM-DD-YYYY');

    this.setState({ weekStart: start, weekEnd: end })
    this.getIncidentsFilteredByDate({ weekStart: start, weekEnd: end });

  }

  getPreviousWeek = () => {
    // var end = moment(this.state.weekStart).format('MM-DD-YYYY');
    // end = moment(end).subtract(1, 'days').format('MM-DD-YYYY');
    // var start = moment(end).subtract(6, 'days').format('MM-DD-YYYY');

    var end = moment(this.state.weekStart).subtract(1, "days").format('MM-DD-YYYY');
    var start = moment(end).startOf('month').format('MM-DD-YYYY');


    this.setState({ weekStart: start, weekEnd: end })
    this.getIncidentsFilteredByDate({ weekStart: start, weekEnd: end });

  }



  render() {
    return (
      <>
        <PanelHeader
          size="sm"
          // content={
          //   <Line
          //     data={this.state.IncidentRegisterLineGragh.data}
          //     // data={dashboardPanelChart.data}
          //     // options={dashboardShippedProductsChart.options}
          //     options={dashboardPanelChart.options}
          //   />
          // }
        />
        <div className="content">
          <Row>
            <Col xs={12} md={12}>
              <Card>
                <CardHeader>
                  <Row >

                    <div class="col-md-12">
                      <nav class="navbar navbar-expand-lg bg-info">
                        <div class="container">
                          <a class="navbar-brand" href="#">Monthly reported Incidents</a>
                          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#example-navbar-icons" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-bar bar1"></span>
                            <span class="navbar-toggler-bar bar2"></span>
                            <span class="navbar-toggler-bar bar3"></span>
                          </button>
                          <div class="collapse navbar-collapse" id="example-navbar-icons">
                            <ul class="navbar-nav ml-auto">
                              <li class="nav-item">
                                <a class="nav-link" onClick={this.getPreviousWeek}><i class="now-ui-icons arrows-1_minimal-left" aria-hidden="true"></i></a>
                              </li>
                              <li class="nav-item active">
                                <a class="nav-link" href="#">
                                  <p>{moment(this.state.weekStart).format('ddd') + ', ' + moment(this.state.weekStart).format('DD') + ' ' + moment(this.state.weekStart).format('MMM')} - {moment(this.state.weekEnd).format('ddd') + ', ' + moment(this.state.weekEnd).format('DD') + ' ' + moment(this.state.weekEnd).format('MMM')}</p>
                                </a>
                              </li>
                              <li class="nav-item">
                                <a class="nav-link" onClick={this.getNextWeek}><i class="now-ui-icons arrows-1_minimal-right" aria-hidden="true"></i></a>
                              </li>
                              <li class="nav-item">
                                <Form>
                                  <InputGroup className="no-border">
                                    <Input
                                      name="searchInput"
                                      defaultValue={this.state.searchInput}
                                      onChange={(event) => {
                                        this.handleChange(event);
                                      }}
                                      id="input1"
                                      type="text"
                                      placeholder="Search participant name"
                                    />
                                    <InputGroupAddon addonType="append">
                                      <InputGroupText>
                                        <i className="now-ui-icons ui-1_zoom-bold" />
                                      </InputGroupText>
                                    </InputGroupAddon>
                                  </InputGroup>
                                </Form>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </nav>
                    </div>

                  </Row>
                </CardHeader>
                <CardBody>
                  <Table responsive>
                    <thead className="text-info">
                      <tr>
                        <th>Time/date</th>
                        <th className="text-truncate" style={{ "maxWidth": "700px" }}>Incident</th>
                        <th>Participant</th>
                        <th>Incident category</th>
                        <th className="text-right">Impact</th>
                      </tr>
                    </thead>
                    <tbody>

                      {
                        this.state.incidents.map(element => {
                          return (
                            <tr>
                              <td> {moment(element.date).format('DD/MM/YYYY')} {moment(element.date).format('HH:mm')} </td>
                              <td className="text-truncate" style={{ "maxWidth": "700px" }}> {element.descriptionAfter}  </td>
                              <td> {element.tblparticipant ? element.tblparticipant.firstname : ''} {element.tblparticipant ? element.tblparticipant.lastname : ''} {!element.tblparticipant ? element.otherNotParticipant : ''} </td>
                              <td> {element.category} </td>
                              <td className="text-right"> {element.impactAssessiment} </td>
                            </tr>
                          )
                        })
                      }

                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default Dashboard;
