/*!

=========================================================
* Now UI Dashboard React - v1.4.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/now-ui-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "views/Dashboard";
import IncidentRegister from "./views/IncidentRegister";
import IncidentReport from "./views/IncidentReport";
import CorrectiveActionFilled from "./views/CorrectiveActionFilled"

var dashRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "design_app",
    component: Dashboard,
    layout: "/admin",
    accessLevel:7
  },
  {
    path: "/incident-report",
    name: "Incident report",
    icon: "design_image",
    component: IncidentReport,
    layout: "/admin",
    accessLevel:7
  },
  {
    path: "/incident-register",
    name: "Incident register",
    icon: "ui-1_bell-53",
    component: IncidentRegister,
    layout: "/admin",
    accessLevel:7
  },
  {
    path: "/corective-action-filled",
    name: "Corrective register",
    icon: "users_single-02",
    component: CorrectiveActionFilled,
    layout: "/admin",
    accessLevel:1
  }
];
export default dashRoutes;
